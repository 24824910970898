export const ReservationStep = {
  GuestDetail: 0,
  Payment: 1,
  Confirmation: 2
}

export const RoomLotteryStep = {
  GuestDetail: 0,
  Confirmation: 1
}

export const BOOKING_METHOD = {
  WORDPRESS: 'wordpress',
  CHATBOT: 'chatbot',
  CHATBOT_BOOKING_WIDGET_OFF: 'chatbot_booking_widget_off',
  CHATBOT_BOOKING_WIDGET_ON: 'chatbot_booking_widget_on',
  BOOKING_WIDGET: 'booking_widget',
  BOOKING_WIDGET_TO_TRIPLA_OTA: 'booking_widget_to_tripla_ota',
  TRIPLA_OTA: 'tripla_ota'
}

export const RESERVED_CHANNEL = {
  ALL: 'all',
  OFFICIAL: 'officialWebsite',
  TRIPLA: 'tripla',
  WBF: 'wbf'
}

export const PAYMENT_TYPE = {
  PRE_PAID: 'pre_paid',
  ON_SITE: 'on_site',
  DEFERRED: 'deferred_payment',
  CREDIT_CARD_REQUIRED: 'on_site_credit_card_required'
}

export const PAYMENT_STATUS = {
  PRE_3D_SECURE: 'pre_3d_secure',
  CAPTURING: 'capturing',
  CAPTURED: 'captured',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  AUTHORIZED: 'authorized'
}

export const DISCOUNT_TYPE = {
  CREANSMAERD: 'creansmaerd',
  ALL_USERS: 'all_users',
  COUPON: 'coupon',
  PAYMENT: 'payment'
}

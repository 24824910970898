import store from 'store'

export function appendStyleInHead(compiledCSS) {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.appendChild(document.createTextNode(compiledCSS))
  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(style)
}

export function createElementFromHTML(htmlString) {
  const div = document.createElement('div')
  div.innerHTML = htmlString.trim()
  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild
}

export function appendNodeInHead(string) {
  // TODO: should we sanitize the input here??
  if (!string.trim()) return
  const newNode = createElementFromHTML(string)
  const head = document.head || document.getElementsByTagName('head')[0]
  head.appendChild(newNode)
}

export function getGaSessionID() {
  if (typeof window === 'undefined' || !window.ga || typeof window.ga.getAll !== 'function')
    return null

  const trackers = window.ga.getAll()

  return trackers.length > 0 ? 'GA1.2.' + trackers[0].get('clientId') : null
}

export function sendGa4ToParentWindow(data) {
  try {
    const parent = window.top
    if (!parent || !isInIframe()) return
    parent.postMessage(
      {
        action: 'ga4Event',
        ...data
      },
      store.getters['setting/getSetting'].parentUrl
    )
  } catch (e) {
    console.error('sendGa4ToParentWindow failed')
    console.error(e)
  }
}

export function sendDataToParentWindowOnRecommenderWidget(data) {
  try {
    const parent = window.top
    if (!parent) return
    const urlParams = new URLSearchParams(window.location.search)
    parent.postMessage(data, urlParams.get('parentUrl'))
    if (process.env.TARGET_ENV !== 'production') {
      console.log('recommender widget send:', data, urlParams.get('parentUrl'))
    }
  } catch (e) {
    console.error('sendDataToParentWindow failed')
    console.error(e)
  }
}

export function sendDataToParentWindowForBrrWidget(data) {
  try {
    const parent = window.top
    if (!parent) return
    const urlParams = new URLSearchParams(window.location.search)
    parent.postMessage(data, urlParams.get('parentUrl'))
    if (process.env.TARGET_ENV !== 'production') {
      console.log('for brr widget send:', data, urlParams.get('parentUrl'))
    }
  } catch (e) {
    console.error('sendDataToParentWindow failed')
    console.error(e)
  }
}

// CAUTION:
// Sending sensitive data  to hotel's website is potentially a security risk.
export function sendDataToParentWindow(data, parentUrl) {
  const isRecommenderWidget = location.pathname.includes('/recommender-widget')
  if (isRecommenderWidget) return
  // window.top refers to the outermost frame
  // use case: BW is used as an iframe in CB
  if (process.env.TARGET_ENV !== 'production') {
    console.log('BW send:', data)
  }
  try {
    let parent
    if (
      (store.getters['setting/isChatbotEnabledOnBW'] && store.getters['setting/isOpenInNewTab']) ||
      store.getters['setting/isInChatbotIframe']
    ) {
      parent = window.top
    } else {
      // we can't use window.top here coz Custom Bot Test site is separate domain and displayed in CM as an iframe
      // so window.parent is the Custom Bot Test domain but window.top is CM domain which is not matched with parentUrl for the postMessage's second param targetOrigin
      parent = window.opener || window.parent
    }

    if (parent) {
      parent.postMessage(
        {
          ...data,
          isInCBIframe: store.getters['setting/isInChatbotIframe']
        },
        parentUrl ||
          store.getters['setting/getSetting'].parentUrl ||
          localStorage.getItem('parentUrl')
      )
    }
  } catch (e) {
    console.error('sendDataToParentWindow failed')
    console.error(e)
  }
}

window.sendDataToParentWindow = sendDataToParentWindow

export function browserObsolete() {
  return isIE10AndBelow()
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function isIE10AndBelow() {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return true
  }

  // other browser
  return false
}

export function isSafari12AndBelow() {
  return (
    window.navigator.vendor &&
    window.navigator.vendor.indexOf('Apple') > -1 &&
    window.navigator.userAgent &&
    window.navigator.userAgent.includes('CriOS') === false &&
    window.navigator.userAgent.includes('FxiOS') === false &&
    (window.navigator.userAgent.includes('Version/12') === true ||
      window.navigator.userAgent.includes('Version/11') === true ||
      window.navigator.userAgent.includes('Version/10') === true)
  )
}

export function isLine() {
  return /[\W]line[\W]/.test(navigator.userAgent)
}

export function iOSVersion() {
  let d, v
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
    d = {
      status: true,
      version: parseInt(v[1], 10),
      info: parseInt(v[1], 10) + '.' + parseInt(v[2], 10) + '.' + parseInt(v[3] || 0, 10)
    }
  } else {
    d = { status: false, version: false, info: '' }
  }
  return d
}

export function isSafariDesktop() {
  const uA = navigator.userAgent
  const vendor = navigator.vendor
  return /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)
}

export function isSafari() {
  return /^((?!chrome|android|CriOS).)*safari/i.test(navigator.userAgent)
}

export function isInIframe() {
  if (process.env.NODE_ENV === 'development') return true
  return window.self !== window.top
}

export function isMobile() {
  // custom bot test iframe size is always 370x650 so we need to display UI view for it regardless of userAgent
  if (window.location.toString().includes('custom_bot_test=true')) return true

  const checkScreenWidth = window.innerWidth <= 992
  return checkScreenWidth
}

export function isRealMobile() {
  // custom bot test iframe size is always 370x650 so we need to display UI view for it regardless of userAgent
  if (window.location.toString().includes('custom_bot_test=true')) return true

  let checkUserAgent = false
  const checkScreenWidth = window.innerWidth <= 992
  /* eslint-disable no-useless-escape */
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      checkUserAgent = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  /* eslint-enable no-useless-escape */
  return checkUserAgent && checkScreenWidth
}

export function scrollToTop(containerSelector = '.app-wrapper') {
  window.scrollTo(0, 0)
  // Sometimes window.scrollTo doesn't work because the one being scroll is not the <body> element
  // but some containers
  const target = document.querySelector(containerSelector)
  if (target) {
    target.scrollTop = 0
  }
}

export function scrollToTopSmooth(containerSelector = '.app-wrapper') {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  const target = document.querySelector(containerSelector)
  if (target) {
    target.scrollTop = 0
  }
}

export const scrollToError = (selector = '.is-invalid') => {
  const dom = document.querySelector(selector)
  if (!dom) return
  const domRect = dom.getBoundingClientRect()
  if (domRect) {
    if (isInIframe() && isMobile()) {
      return
    }
    window.scrollTo({
      top: domRect.top + document.documentElement.scrollTop - 25,
      behavior: 'smooth'
    })
  }
}
